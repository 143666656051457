(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/keylistener/assets/javascripts/keylistener.js') >= 0) return;  svs.modules.push('/components/utils/keylistener/assets/javascripts/keylistener.js');


var svs = svs || {};

(function(svs) {
  'use strict';

  svs.utils = svs.utils || {};
  svs.utils.keylistener = svs.utils.keylistener || {};

  svs.utils.keylistener.addKeyboardListener = function() {
    return 'no-key';
  };

  svs.utils.keylistener.removeKeyboardListener = function() {
    return true;
  };
})(svs);


 })(window);